#root {
  background-color: #f6f6f6;
}
.Maindashboard .heading .h2color {
  color: var(--secondary-color) !important;
}

.Maindashboard .heading .heading-fs {
  font-weight: 700;
}

.Maindashboard .create button {
  color: white;
  border-radius: 7px;
  font-weight: 600;
  font-size: 17px;
  padding: 10px 20px 10px 40px;
  background-color: var(--primary-color) !important;
}

.Maindashboard .plusIcon {
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 17px;
  color: white;
}

.Maindashboard .firstcard {
  padding: 25px 28px;
  border-radius: 12px;
}

.Maindashboard .iconPd {
  padding: 10px;
  border-radius: 12px;
  background: var(--primary-color);
}
.Maindashboard .iconPd .texticon {
  font-size: 3rem;
  color: #fff;
}
.Maindashboard .iconPd-second {
  padding: 10px;
  border-radius: 12px;
  background: var(--secondary-color);
}
.Maindashboard .iconPd-second .texticon {
  font-size: 3rem;
  color: #fff;
}

.Maindashboard .left-img {
  width: 40px;
}

.Maindashboard .para-alpha {
  color: Grey;
  font-size: 18px;
}

.Maindashboard .para-num {
  font-size: 2rem;
}

.Maindashboard .GuidenessCard {
  background-color: var(--primary-color) !important;
  color: white;
  padding: 25px;
  border-radius: 16px;
  min-height: 257px;
  max-height: 257px;
  height: 100%;
}

.partypic-img {
  min-height: 257px;
  max-height: 257px;
  height: 100%;
}

.Maindashboard .arrow {
  font-size: 24px;
}

.Maindashboard .partypic {
  border: none !important;
  background-image: url(../images/Partypic.png);
  background-repeat: no-repeat;
}

.Maindashboard .partypic {
  border: none !important;
  background-image: url(../images/Partypic.png);
  background-repeat: no-repeat;
}

.Maindashboard .para-curently {
  font-weight: 500;
}

.Maindashboard .form-card {
  border-radius: 7px;
  font-weight: bold;
  padding: 1.5rem;
  border: none !important;
}

.Maindashboard .playicon {
  top: -42px;
  position: absolute;
  width: 84px;
  height: 90%;
  right: 10px;
  background: var(--primary-color);
  border: none !important;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 27px;
  font-size: 30px;
}
.Maindashboard .span {
  position: absolute;
  font-size: 14px;
  top: 6px;
  left: 25px;
}

.Maindashboard .progress-styling {
  font-size: 17px;
}

.Maindashboard .mastering {
  font-weight: 900 !important;
}

.Maindashboard .GuidenessCard p {
  font-size: 17px;
  color: rgba(255, 255, 255, 0.75);
}
.Maindashboard .GuidenessCard .vectureimg {
  width: 40px;
}
.Maindashboard .GuidenessCard2 {
  background-color: var(--secondary-color) !important;
}
.Maindashboard .GuidenessCard .heading-styling {
  line-height: 40px;
  font-size: 2rem !important;
  font-weight: 700;
}
@media only screen and (min-width: 992px) and (max-width: 1140px) {
  .Maindashboard .firstcard {
    height: 120px;
    padding: 20px 16px !important;
  }
  .Maindashboard .iconPd {
    padding: 10px !important;
  }
  .para-alpha {
    font-size: 16px !important;
  }
  .Maindashboard .texticon {
    font-size: 31px !important;
  }
  .Maindashboard .para-num {
    font-size: 24px !important;
  }
  .Maindashboard .mastering {
    font-size: 16px !important;
  }
  .Maindashboard .para-curently {
    font-size: 15px;
  }
  /* .Maindashboard .GuidenessCard {
    padding: 39px 38px;
  } */
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .btn_group .btn-secondary {
    padding: 20px 23px !important;
  }
  .Maindashboard .firstcard {
    padding: 20px 13px !important;
  }

  .Maindashboard .iconPd {
    padding: 7px !important;
  }

  .para-alpha {
    font-size: 15px !important;
  }
  .Maindashboard .texticon {
    font-size: 37px !important;
  }
  .Maindashboard .para-num {
    font-size: 22px !important;
  }

  /* .Maindashboard .GuidenessCard {
    padding: 45px 25px !important;
  } */
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .Maindashboard .GuidenessCard .heading-styling {
    font-size: 1.5rem !important;
    line-height: 36px !important;
  }
}

@media only screen and (min-width: 1141px) and (max-width: 1400px) {
  .Maindashboard .firstcard {
    padding: 25px 17px !important;
    height: 120px;
  }

  .Maindashboard .para-alpha {
    font-size: 14px !important;
  }
  .Maindashboard .para-num {
    font-size: 1.8 !important;
  }
}

.Explore .searchfieldicon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 17px;
  color: #e5e5e5;
}

.Explore .searchfield {
  border: none;
  border-radius: 8px;
  padding: 0.2rem 0.75rem 0.5rem 0.75rem !important;
}
.Explore .searchfield::placeholder {
  color: #bdbdbd;
  font-size: 12px;
  top: -2px;
}

.firstpara {
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 2px;
}

.secpara {
  font-size: 14px;
  font-weight: 700 !important;
}

/* .Explore .slick-slide img {
  width: 100%;
} */

.Explore .category h2,
.myCourses h2,
.related-courses h2,
.Explore .Courses h2 {
  /* text-decoration: underline; */
  font-weight: 800;
  font-size: 24px;
}

.Explore .slick-arrow {
  border-radius: 50%;
  height: 46px;
  width: 46px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* background-color: #229B9F; */
  background-color: transparent;
  /* top: 32% !important; */
  display: none !important;

  z-index: 1;
}

.Explore .slick-arrow:before {
  font-family: auto !important;
  /* font-size: 65px !important; */
  /* opacity: 1 !important; */
  opacity: 0 !important;
  display: none !important;
  /* margin-bottom: 56px !important; */
}

/* .Explore .firstcard .slick-arrow:hover {
    background-color: #229B9F !important;
}
.Explore .firstcard .slick-arrow:focus {
    background-color: #229B9F !important;
} */

.Explore .firstcard .slick-prev {
  left: -14px;
}
.Explore .firstcard .slick-prev:before {
  content: " \02FF" !important;
}

.Explore .firstcard .slick-next {
  right: -14px;
}

.Explore .firstcard .slick-next::before {
  content: " \02F2" !important;
}

.Explore .firstcard .icons {
  position: absolute;
  border-radius: 50%;
  height: 46px;
  width: 46px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: var(--secondary-color);
  transform: translate(0, -43%);
  top: 43%;
  z-index: 1;
  font-size: 65px !important;
  color: white;
}
/* .Explore .firstcard .edutech-icon {
  top: 5.8rem !important;
} */
.Explore .firstcard .right-icon {
  right: 0%;
}

.Explore .firstcard .left-icon {
  left: 14px;
  display: none !important;
}

.Explore .category p {
  position: absolute;
  top: 19px;
  font-weight: 900;
  left: 24px;
}

.Explore .Courses .para {
  position: absolute;
  top: 29px;
  font-weight: 900;
  left: 18px;
}

.Explore .topcourses {
  font-weight: 800;
  font-size: 18px;
}

.Explore .slick-slider {
  padding: 0px 10px 0px 0px;
}

.Maindashboard .prgbar {
  width: 85%;
}

@media only screen and (max-width: 576px) {
  .Explore .seacrh-div {
    border: none;
    width: 100% !important;
  }
}

.progresss {
  font-weight: 600;
  text-decoration: underline;
}
.myCourses .main_header .filter {
  font-size: 16px;
  margin-top: 6px;
  font-weight: 500;
}

.myCourses .main_header .selectoption .form-control {
  border: none !important;
  border-radius: 8px !important;
  font-size: 0.9rem;
  color: black !important;
}

.main_header .searchfieldicon {
  position: absolute;
  top: 13px;
  right: 10px;
  font-size: 17px;
  color: #e5e5e5;
}

.main_header .searchfield {
  border: none !important;
  border-radius: 8px !important;
  height: calc(1.5em + 0.75rem + 8px);
  background-color: #fff !important;
  padding: 0.375rem 2.2rem 0.375rem 0.75rem !important;
}
.main_header .searchfield::placeholder {
  color: #bdbdbd;
  font-size: 13px;
}

.myCourses .Pline {
  position: absolute;
  top: 29px;
  font-weight: 900;
  left: 13px;
}

.myCourses .cardstyle {
  padding: 7px 15px;
  border-radius: 30px;
  border-color: #18969a;
}
.myCourses .cardwidth {
  width: 88% !important;
}
.myCourses .cardstyle .dots {
  font-size: 22px;
  color: #c4c4c4;
  position: absolute;
  top: 8px;
  right: 16px;
}

.myCourses .cardstyle .cardheading {
  margin-top: 16px;
}
.myCourses .text-icon {
  background-color: #18969a;
  display: flex;
  justify-content: end;
  align-items: center;
  color: white;
  padding: 10px;
  border-radius: 9px;
  font-size: 28px;
}

.myCourses .cardstyle .Cpara {
  font-weight: 600;
  color: #18969a;
  line-height: 20px;
}

.myCourses .cardstyle h4 {
  font-weight: 700;
  color: #424242;
  font-size: 23px;
}

.pendingCourses {
  /* background-color: #efefef !important; */
  padding: 20px 0px 5px 0px;
}

.myCourses .Plines {
  position: absolute;
  top: 15px;
  font-weight: 900;
  left: 15px;
}

.myCourses .cardstyle h4 {
  font-weight: 700;
  color: #424242;
  font-size: 21px;
}

.myCourses .pendingCourses .card-width {
  width: 94% !important;
}
.myCourses .Courseiframeheight .video-height {
  border-radius: 15px !important;
}
/* .myCourses .card-width {
  max-width: 29%;
} */

.myCourses .pendingCourses .heightline {
  margin-top: 12px;
  margin-bottom: 7px;
}

.myCourses .pendingCourses .icons {
  position: absolute;
  border-radius: 50%;
  height: 40px;
  width: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: var(--primary-color);
  top: 48% !important;
  z-index: 1;
  font-size: 43px !important;
  cursor: pointer;
  color: white;
}
.myCourses .pendingCourses .right-icon {
  right: 2%;
}

.secondary {
  color: var(--secondary-color) !important;
}
.myCourses .slick-arrow {
  display: none !important;
}

.myCourses .CurentCourses {
  padding: 22px 0px;
  background: #e8e8e8 !important;
}

.myCourses .CurentCourses .mainpara {
  text-decoration: underline;
  font-weight: 600;
}

.myCourses .edutech-dashboard-icon {
  font-size: 21px;
  background-color: var(--primary-color) !important;
  color: white;
  max-height: 80px;
    height: 100%;
}

.pendingCourses .edutech-dashboard-second-card {
  width: 96% !important;
  display: flex;
  flex-direction: column;
  max-width: 37.5rem;
  min-width: 1.3rem;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .myCourses .card-width {
    flex: initial !important;
    max-width: 30%;
  }
}

@media only screen and (min-width: 1200px) {
  .myCourses .main_header .selectoption .form-control {
    width: 127% !important;
  }
}

@media only screen and (max-width: 768px) {
  .next-course-btn {
    width: 50% !important;
  }
  .myCourses .selectoption {
    padding: inherit !important;
  }
  .main_header .searchfieldicon {
    right: 5px !important;
  }
  .masteringinputfield {
    width: 100%;
  }
  .main_header .searchfield {
    width: 100% !important;
  }
}

.coursecard .card {
  padding: 10px;
}

.coursecard img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.coursecard .Firstpara {
  font-size: 18px;
  font-weight: 600;
}

.shortcoursepara {
  font-weight: 700;
  font-size: 17px;
}

.ml-para {
  margin-left: 34px;
}

.profile_info .btn {
  padding: 10px 50px;
  border-radius: 14px;
  background-color: var(--secondary-color);
  color: white !important;
  font-weight: 600;
}

.Profile {
  padding: 2rem;
  background-color: var(--primary-color) !important;
  border-radius: 15px 0 0 15px;
}

.Profile img {
  border-radius: 50%;
  height: 15rem;
  border: 1px solid #fff;
  width: 15rem;
  object-fit: cover;
  margin-bottom: 2rem;
}
.profile_info h4 {
  margin-bottom: 1rem;
}
.profile_info {
  padding: 2rem;
  background-color: #fff;
}
.profile_info h6 {
  margin-bottom: 2rem;
}
.coursecard .heading {
  font-weight: 700;
}
.Courseiframeheight {
  width: 100% !important;
  height: auto !important;
}
.Courses .Courseiframeheight,
.related-courses .Courseiframeheight {
  /* height: 162px; */
  width: 100% !important;
  height: auto !important;
}

.Courseiframeheight .video-height {
  border-radius: 15px !important;
}

.myCourses .edutech-dashboard-second-card {
  background-color: white !important;
  border: 2px #00386e6b solid !important ;
  width: 96% !important;
}

.myCourses .masterheading {
  color: var(--primary-color) !important;
}

.myCourses .da {
  color: var(--secondary-color);
}

.myCourses .bg-Cap-color {
  color: #424242 !important;
  font-weight: 700;
}

.myCourses .dotcolor,
.myCourses .heart-icon {
  color: #e5e5e5 !important;
}

.myCourses .header-bottom-section .hour-section .progress {
  height: 4px !important;
}

.myCourses .edutech-dashboard-second-card .progress-bar {
  background-color: var(--primary-color) !important;
  height: 10px !important;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .myCourses .main_header .selectoption .form-control {
    width: 100% !important;
  }
  .btn_group .btn-secondary {
    padding: 20px 18px !important;
  }
}

@media only screen and (max-width: 576px) {
  .myCourses .main_header .selectoption .form-control {
    width: 100% !important;
  }

  .myCourses .selectoption {
    width: 100%;
  }
  .main_header .searchfield {
    width: 100% !important;
  }

  .main_header .searchfieldicon {
    right: 7px;
  }
}

.Profile .proNameStyling {
  font-size: 22px;
  font-weight: 700;
}

.Profile .pIntro-styling {
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
}

.Profile .span-styling {
  font-weight: 400;
  font-size: 13px;
  color: #bdbdbd;
}

.Profile .profileAbout-mt {
  margin-top: 28px;
}

.Profile .editicon {
  position: absolute;
  right: 22px;
  font-size: 19px;
  top: 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .Profile img {
    min-height: 285px !important;
  }
  .Profile .span-width {
    max-width: 88% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
  .Profile img {
    min-height: 275px !important;
  }
  .Profile .span-width {
    max-width: 85% !important;
  }
}

.Profile .span-width {
  max-width: 53%;
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .profileCards .card-body {
    padding: 13px 4px !important;
  }
  .profileCards .heading {
    font-size: 13px !important;
  }

  .profileCards .Firstpara {
    font-size: 14px !important;
  }

  .profileCards .shortcoursepara {
    font-size: 14px !important;
  }
  .profileCards .timing {
    font-size: 13px !important;
  }
  .profileCards .card-text {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 768px) {
  .secondEditbtn {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {
  .teachergroupbtn .btn-group {
    display: initial !important;
  }
  .teachergroupbtn .btn_group .btn-secondary {
    width: 100%;
  }
  .secondEditbtn {
    display: initial !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .Maindashboard .GuidenessCard p {
    font-size: 16px !important;
  }

  .Maindashboard .GuidenessCard .heading-styling {
    font-size: 1.5rem !important;
  }
}
.teacherCourseModal .divhover {
  padding: 10px 10px;
}

.teacherCourseModal .divhover:active,
.teacherCourseModal .divhover:hover {
  background-color: #f6f6f6;
  padding: 10px 10px;
  border-radius: 10px;
}
.teacherCourseModal .divactive {
  background-color: #f6f6f6;
  border-radius: 10px;
}

.teacherCourseModal img {
  width: 55px;
}

.teacherCourseModal h5 {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 700;
}

.teacherCourseModal p {
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 3px;
  font-weight: 600;
}

.teacherCourseModal .modal-content {
  border-radius: 22px !important;
}

.teacherCourseModal .modal-header {
  border-bottom: none !important;
}

.teacherCourseModal .pricing-div:hover {
  cursor: pointer;
}

.PrizeModal .modal-body {
  padding: 30px 24px;
}

.PrizeModal .form-control {
  border: none !important;
  border-radius: 0px !important;
  background-color: #f6f6f6 !important;
}
.PrizeModal .form-label {
  font-weight: 600;
  font-size: 12px;
}

.PrizeModal .modalheader .left-arrow {
  position: absolute;
  left: 20px;
}
.PrizeModal .modalheader .chevarrow {
  font-size: 17px;
  margin-top: 2px;
}

.PrizeModal .modalheader .small {
  margin-top: 2px;
  font-weight: 700;
  margin-left: 4px;
  font-size: 12px;
  cursor: pointer;
}

.PrizeModal .modalheader p,
.Notesheader p {
  font-weight: 700;
  font-size: 19px;
  margin-bottom: 0px;
}

.PrizeModal .card {
  padding: 14px 15px;
  margin-top: 23px;
  background-color: #f6f6f6 !important;
  border: none;
  border-radius: initial;
}

.PrizeModal .free {
  color: var(--primary-color) !important;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 8px;
}

.PrizeModal .nameplan {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 0px;
}

.PrizeModal .subtitle {
  font-size: 12px;
  color: #989898;
  margin-bottom: 12px;
}

.PrizeModal .rightcoldiv {
  color: var(--primary-color) !important;
  font-weight: 700;
  cursor: pointer;
}
.PrizeModal .right-chev {
  margin-left: 8px;
  margin-top: 5px;
}

.PrizeModal #agreefor::before {
  background-color: #989898 !important;
  border-radius: 2px !important;
  padding: 8px !important;
}

.PrizeModal #agreefor::after {
  border: solid white;
  border-width: 0 3px 3px 0 !important;
  top: 3.2px !important;
  left: 5.7px !important;
  width: 5.5px !important;
  height: 10px !important;
}

.PrizeModal #agreefor {
  font-size: 13px;
  font-weight: 600;
}

.PrizeModal .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.PrizeModal
  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  border: none !important;
  background-color: #f6f6f6 !important;
  height: 150px;
  box-shadow: none !important;
}

.PrizeModal .ck.ck-toolbar .ck.ck-toolbar__separator {
  align-self: initial !important;
}
.PrizeModal
  .ck.ck-toolbar
  > .ck.ck-toolbar__grouped-dropdown
  > .ck.ck-button.ck-dropdown__button {
  display: none !important;
}
.PrizeModal .ck.ck-dropdown .ck-button.ck-dropdown__button .ck-button__label {
  width: 5em !important;
}

.PrizeModal
  .ck.ck-toolbar
  > .ck-toolbar__items
  > :not(.ck-toolbar__line-break) {
  margin-right: 0 !important;
}

.PrizeModal .ck.ck-editor__editable_inline > :first-child {
  font-size: 13px !important;
}
.PrizeModal .ck.ck-dropdown .ck-dropdown__arrow {
  font-size: 14px !important;
}
.PrizeModal .ck.ck-icon {
  font-size: 0.6em !important;
}

.PrizeModal .ck-file-dialog-button {
  display: none !important;
}

.PrizeModal .ck .ck-toolbar .ck-toolbar_grouping {
  border: none !important;
  background-color: #f6f6f6 !important;
}

.PrizeModal .ck.ck-editor .ck-editor__top .ck-sticky-panel .ck-toolbar {
  border: none;
  background-color: #f6f6f6 !important;
}

.PrizeModal .modalclose,
.Notesheader .modalclose {
  position: absolute;
  right: 23px;
  top: 35px;
  cursor: pointer;
  font-size: 17px;
}

.PrizeModal .modal-content {
  border-radius: 22px !important;
}

@media only screen and (max-width: 992px) {
  .PrizeModal .btn-size {
    width: initial !important;
  }
}

.teacherCourseModal .modal-body {
  padding: 42px 0px;
}

.teacherCourseModal .modalclose {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 18px;
  cursor: pointer;
}

.PrizeModal #example-collapse-text {
  font-size: 12px !important;
  margin-top: 4px !important;
  text-align: justify;
}

.PrizeModal .price-modal-dropdown::placeholder {
  font-size: 10px !important;
  margin-left: 0px;
}

.PrizeModal .price-modal-dropdown {
  margin-left: 0px;
  height: 40px;
  border: none !important;
  border-radius: 0px !important;
  background-color: #f6f6f6 !important;
  color: #333 !important;
  font-weight: 600;
  padding-right: 5rem;
}

.PrizeModal .btn-size,
.Notesheader .btn-size {
  padding: 7px 22px;
  font-size: 16px;
  border-radius: 7px;
  font-weight: 400;
  border: none;
  background-color: var(--primary-color);
}
.PrizeModal .btn-size:active {
  border: none !important;
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.PrizeModal .chenvenarrow {
  color: var(--primary-color) !important;
  position: absolute;
  top: 13px;
  right: 15px;
}

.PrizeModal .price-modal-dropdown::after {
  display: none !important;
}

.PrizeModal .btn-success:active:not(:disabled):not(.disabled),
.PrizeModal .show > .btn-success.dropdown-toggle {
  color: #303030 !important;
  background-color: #f6f6f6 !important;
  border: none !important;
  box-shadow: none !important;
}

.PrizeModal .dropdown-menu {
  width: 100%;
}

.Maindashboard .canceled .progress-bar {
  background-color: rgba(170, 12, 12, 0.7) !important;
}
.Maindashboard .pending .progress-bar {
  background-color: rgba(141, 141, 141, 0.7) !important;
}
.Maindashboard .completed .progress-bar {
  background-color: rgba(0, 56, 110, 0.7) !important;
}
.Maindashboard .in-progress .progress-bar {
  background-color: rgba(158, 209, 111, 0.7) !important;
}

.Maindashboard .prgbar .progress {
  border-radius: 5px !important;
}

.Maindashboard .GuidenessCard .Existing-para {
  margin-top: 44px;
}

.Maindashboard .playicon-bgcolor,
.Maindashboard .rightprbar .progress-bar {
  background-color: var(--secondary-color) !important;
}
.scriptionaddbtn {
  background-color: var(--primary-color) !important;
  border: 1px solid #00386e !important;
  font-weight: 600;
}

.icons {
  padding: 10px;
  background-color: var(--primary-color) !important;
  border-radius: 4px;
  color: white;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-bgcolor {
  background-color: red !important;
}

.Cancel-modal-btn {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  font-weight: 600;
}

.edutech-admin-three-dot-icon {
  padding: 2px 1px 4px 5px;
  border: none;
  background: none;
  color: var(--text-secondary);
}
.edutech-admin-dots-inner {
  width: 198px;
  display: flex;
  align-items: end;
  display: flex;
  align-items: center;
}
.edutech .dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
button#clinician-table-three-button-dropdown {
  background: white;
  border: none;
}
.edutech .edutech-admin-primary {
  background: #00386e;
  color: white;
  border-color: #00386e;
}
button.btn.btn-.edutech-admin-primary {
  background: #00386e;
  border-color: #00386e;
  color: white;
  border-radius: 10px;
}

.CircularProgressbar {
  width: 50% !important;
}

.teacher-Coaching-information .para-line-height {
  line-height: 10px;
}

.teacher-Coaching-information .img-mtp {
  margin-top: 2.1rem;
}

.teacher-Coaching-information .fw-600 {
  font-weight: 600;
}

.teacher-Coaching-information button {
  width: fit-content;
  background: white;
  border-radius: 5px;
  border: 1px solid red;
  padding: 11px 29px;
  color: red;
}

.teacher-Coaching-information li {
  font-size: 13px;
}

.fw-700 {
  font-weight: 700;
}

.Coaching-Grouping .checkbox-border label {
  font-size: 15px !important;
  text-decoration: underline !important;
}

.Coaching-Grouping .checkbox-border label:before {
  border-radius: 50% !important;
}

.Coaching-Grouping .css-8t49rw-MuiStepConnector-line {
  display: none !important;
}

/* .zz {
  display: block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */

.ReviewandSaveCard label {
  font-size: 13px;
}

.btn-finishUp {
  width: fit-content;
  background: white;
  border-radius: 5px;
  border: 1px solid black;
  padding: 8px 36px;
}

.calendarpadding {
  padding: 8px 35px !important;
}

.editfinishtUp2 {
  padding: 8px 51px !important;
}

.calender-fs {
  font-size: 13px !important;
  font-weight: 600 !important;
}

.calendar-fs {
  font-weight: 600;
  font-size: 14px !important;
}

.calendarurlpara {
  font-size: 12px;
}

.removecursor:hover {
  cursor: pointer;
}

.saveandcontinuebtn {
  padding: 15px 2rem 15px 2rem !important;
}

.finishUp .toadmindashboard {
  text-decoration: underline;
  font-weight: 600;
  justify-content: center;
  display: flex;
}

.teacher-curriculum .iconsstyling {
  border: 1px solid;
  border-radius: 50%;
  font-size: 40px;
  width: 50px;
  padding: 8px;
  background-color: #00386e;
  color: white;
  cursor: pointer;
}

.teacher-curriculum .closecolor {
  background-color: white !important;
  color: #00386e !important;
}

.teacher-curriculum .penicon {
  font-size: 18px;
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .Maindashboard .firstcard {
    padding: 25px 17px !important;
    height: 145px;
    display: flex;
    justify-content: center;
  }
}

/* .btn-primary:focus {
  background-color: #00386e !important;
  border-color: #00386e !important;
} */

.avatar-img {
  width: initial !important;
}

#pricing-table {
  text-align: center;
  height: 100vh;
}
#pricing-table .slick-slider .slick-list {
  margin: 0 5rem;
}

#pricing-table .Plan {
  background: white !important;
  -webkit-transform: scaleY(0.9);
  transform: scaleY(0.9);
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px,
    rgb(0 0 0 / 6%) 0px 2px 4px -1px;
  border-radius: 7px;
  /* width: 108% !important;
  max-width: 320px;
  min-width: 320px; */
}
#pricing-table .slick-center .Plan {
  -webkit-transform: scaleY(1) !important;
  transform: scaleY(1) !important;
  padding: 0 1rem;
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
    rgb(0 0 0 / 4%) 0px 10px 10px -5px;
}

#pricing-table #most-popular {
  z-index: 2;
  top: -13px;
  border-width: 3px;
  padding: 30px 20px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 20px 0 10px -10px rgba(0, 0, 0, 0.15),
    -20px 0 10px -10px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 20px 0 10px -10px rgba(0, 0, 0, 0.15),
    -20px 0 10px -10px rgba(0, 0, 0, 0.15);
  box-shadow: 20px 0 10px -10px rgba(0, 0, 0, 0.15),
    -20px 0 10px -10px rgba(0, 0, 0, 0.15);
}

#pricing-table .plan:nth-child(1) {
  -moz-border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px;
}

#pricing-table .plan:nth-child(4) {
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}

/* --------------- */

#pricing-table h3 {
  font-size: 20px;
  font-weight: normal;
  padding: 10px;
  border-radius: 7px;
  background-color: white;
  letter-spacing: 2px;
  font-weight: 500;
  /* background-image: -moz-linear-gradient(#fff,#eee);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#eee));    
  background-image: -webkit-linear-gradient(#fff, #eee);
  background-image: -o-linear-gradient(#fff, #eee);
  background-image: -ms-linear-gradient(#fff, #eee);
  background-image: linear-gradient(#fff, #eee); */
}

#pricing-table #most-popular h3 {
  background-color: #ddd;
  background-image: -moz-linear-gradient(#eee, #ddd);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#eee),
    to(#ddd)
  );
  background-image: -webkit-linear-gradient(#eee, #ddd);
  background-image: -o-linear-gradient(#eee, #ddd);
  background-image: -ms-linear-gradient(#eee, #ddd);
  background-image: linear-gradient(#eee, #ddd);
  margin-top: -30px;
  padding-top: 30px;
  -moz-border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}

#pricing-table .plan:nth-child(1) h3 {
  -moz-border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  border-radius: 5px 0 0 0;
}

#pricing-table .plan:nth-child(4) h3 {
  -moz-border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  border-radius: 0 5px 0 0;
}

#pricing-table h3 span {
  display: block;
  font: bold 25px/100px Georgia, Serif;
  color: #777;
  background: #fff;
  border: 5px solid #fff;
  height: 100px;
  width: 100px;
  margin: 10px auto -65px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  -moz-box-shadow: 0 5px 20px #ddd inset, 0 3px 0 #999 inset;
  -webkit-box-shadow: 0 5px 20px #ddd inset, 0 3px 0 #999 inset;
  box-shadow: 0 5px 20px #ddd inset, 0 3px 0 #999 inset;
}

/* --------------- */

#pricing-table ul {
  margin: 20px 0 0 0;
  padding: 0;
  list-style: none;
}

#pricing-table li {
  border-top: 1px solid #ddd;
  padding: 10px 0;
}

/* --------------- */

#pricing-table .signup {
  position: relative;
  padding: 8px 20px;
  margin: 20px 0 0 0;
  color: #fff;
  font: bold 14px Arial, Helvetica;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  background-color: #72ce3f;
  background-image: -moz-linear-gradient(#72ce3f, #62bc30);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#72ce3f),
    to(#62bc30)
  );
  background-image: -webkit-linear-gradient(#72ce3f, #62bc30);
  background-image: -o-linear-gradient(#72ce3f, #62bc30);
  background-image: -ms-linear-gradient(#72ce3f, #62bc30);
  background-image: linear-gradient(#72ce3f, #62bc30);
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5), 0 2px 0 rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5),
    0 2px 0 rgba(0, 0, 0, 0.7);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5), 0 2px 0 rgba(0, 0, 0, 0.7);
}

#pricing-table .signup:hover {
  background-color: #62bc30;
  background-image: -moz-linear-gradient(#62bc30, #72ce3f);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#62bc30),
    to(#72ce3f)
  );
  background-image: -webkit-linear-gradient(#62bc30, #72ce3f);
  background-image: -o-linear-gradient(#62bc30, #72ce3f);
  background-image: -ms-linear-gradient(#62bc30, #72ce3f);
  background-image: linear-gradient(#62bc30, #72ce3f);
}

#pricing-table .signup:active,
#pricing-table .signup:focus {
  background: #62bc30;
  top: 2px;
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.7) inset;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.7) inset;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.7) inset;
}

#pricing-table .icons-styling {
  background: white;
  width: 50px;
  height: 50px;
  padding: 8px;
  border-radius: 50px;
  font-size: 6px;
  color: var(--secondary-color) !important;
}

#pricing-table .header-div {
  background-color: #e9f2f2;
}

.header-div p {
  font-size: 11px;
}

/* 
#pricing-table .slick-initialized .slick-slide{
  background: white !important;
} */

#pricing-table .checksyling {
  font-size: 19px;
  color: var(--secondary-color);
  margin-top: 2px;
}

#pricing-table .middle-div p {
  font-size: 14px;
  font-weight: 500;
}

#pricing-table .header-div h2 {
  font-weight: 700;
  color: #636363;
}
#pricing-table .header-div p {
  margin-top: 6px;
  font-weight: 700;
  line-height: 14px;
  color: #636363;
}

#pricing-table .footer-div button {
  border: 1px solid black;
  padding: 8px 43px;
  font-size: 12px;
  border-radius: 50px;
  font-weight: 600;
  background-color: white !important;
}

#pricing-table .btn-group button {
  padding: 8px 41px !important;
  font-weight: 700;
}

#pricing-table .btn-group {
  margin: 1.8rem 0;
}

.m_btn {
  background-color: var(--primary-color);
  color: white;
}

.m_btn:hover {
  background-color: var(--primary-color);
  color: white;
}

#pricing-table .Center-Card .footer-div button {
  background-color: var(--primary-color) !important;
  color: white !important;
  border-color: var(--primary-color) !important;
}

#pricing-table .LastCard .footer-div button {
  background-color: var(--secondary-color) !important;
  color: white !important;
  border-color: var(--secondary-color) !important;
}

#pricing-table .slick-arrow,
#pricing-table .slick-prev {
  display: block !important;
}
#pricing-table .slick-prev:before,
.slick-next:before {
  font-family: "slick";
  color: #00386e !important;
  font-size: 40px;
  line-height: 1;
  position: absolute;
  top: 3px;
  left: 0px;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#pricing-table .slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.teachergroupbtn {
  position: absolute;
  bottom: 0px !important;
}

.dashboard-teacher .table td {
  border-top: initial !important;
}

.dashboard-teacher .tr-top-border {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.dashboard-teacher .tr-bottom-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.pricingmodal .ckeditor-modification .ck {
  margin-top: 0.25rem;
}

.pricinglistModal .modal-content {
  padding: 25px 25px !important;
}

.fs-13 {
  font-size: 13px;
}

.fw-600 {
  font-weight: 600;
}

.NoteSize {
  font-size: 19px;
  color: var(--primary-color);
  font-weight: 500;
}

.NoteSize .icon {
  font-size: 16px;
  margin-left: 5px;
  margin-top: -3px;
  color: var(--primary-color);
}

.Coursebackground {
  background-color: #efefef !important;
}

.relatedbackground {
  background-color: #e8e8e8 !important;
}
.Notesheader .modalclose {
  top: 21px !important;
}

.submit-quiz {
  background-color: var(--primary-color) !important;
  padding: 7px 28px !important;
  font-size: 18px;
  font-weight: 600;
  border-radius: 7px;
}

.fw-bold {
  font-weight: bold;
}

.Certication .firstheading {
  font-size: 30px;
}

.Certication .para {
  font-size: 15px;
  font-weight: 600;
}

.certicate-card {
  background-color: #afadad59 !important;
}

.Certication .btn {
  font-size: 16px;
}

.certicate-card .recomend-para {
  font-size: 18px;
}
.not-allowed {
  cursor: not-allowed;
}

.input-box-ml{
  margin-left: 4rem;
}