.edutech-payment-plan {
    font-family: "Space Mono", monospace;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    height: 200px;
    width: 320px;
    flex: 0 0 auto;
    padding: 0 1em;
}

.edutech-payment-plan .card {

    height: 100%;
    border-radius: 8px;
    box-shadow: 1px 1px #aaa3a3;
    background: linear-gradient(45deg, #343a40, #666666, #343a40);
    color: #fff;
}

.edutech-payment-plan .cardNumber {
    position: relative;
    top: 75px;
    display: flex;
    justify-content: space-between;
    font-size: 1.2em;
    word-spacing: 4px;
    letter-spacing: 2px;
    padding: 0 1em;
}

.edutech-payment-plan .cardInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
    position: relative;
    top: 110px;
    padding: 0 1em;
}

.edutech-payment-plan span {
    font-size: 11px;
}

.edutech-payment-plan p {
    margin-top: 8px;
    font-size: 16px;
}

.edutech-payment-plan .cardExpiry {
    text-align: right;
}

.edutech-payment-plan-textinput {
    color: #343a40;
}

.edutech-payment-plan-textinput label {
    display: inline;
}

.edutech-payment-plan-textinput input {
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    outline: none;
    border: 1px solid #ebecee;
    padding: 10px;
    margin: 10px 0;
}

.edutech-payment-plan-textinput input:focus {
    border-color: #64b5f6;
}
.edutech-payment-plan-cardform{
    flex: 1 0 auto;
    background-color: #e0e0e0;
    border-radius: 8px;
    max-width: 400px;
    overflow: hidden;
    padding: 1em 2em;
    box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.5);
}
.edutech-payment-plan-cardform  h2 {
    color: #343a40;
    margin: 0;
    padding-top: .25em;
    border-bottom: 1px solid #aeaeae;
    padding-bottom: .75em;
  }
  .edutech-payment-plan-cardform   ul {
    list-style: none;
    padding: 0;
  }
  .edutech-payment-plan-cardform li:not(:last-child) {
    margin-bottom: 15px;
  }
  .edutech-payment-plan-styled-app{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 3em;
    justify-content: space-around;
    
  }
